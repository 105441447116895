import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";


const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  UserData: any;

  private httpOptions = {
    headers: new HttpHeaders({
      "Accept": "application/json",
      "Content-Type": "application/json"
    }),
    observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
  };

  constructor(private http: HttpClient) {
  }

  login(data: any): Observable<any> {
    return this.http.post<any>(`${URL}/login`, JSON.stringify(data), this.httpOptions).pipe(
      map((response: any) => response)
    );
  }

  isLoggedIn(): boolean {
    const storedData = localStorage.getItem('UserData');
    return storedData !== null;
  }

  requestResetPassword(data: any) {
    return this.http.post<any>(`${URL}/request-password-reset`, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  checkTokenExpirationRequestResetPassword(data: any) {
    return this.http.post<any>(`${URL}/request-password-reset/check-token`,
      JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  resetPassword(data: any) {
    return this.http.post<any>(`${URL}/reset-password`, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }

  changePassword(data: any) {
    return this.http.post<any>(`${URL}/change-password`, JSON.stringify(data), this.httpOptions).pipe(
      map(response => response)
    );
  }


}
