import {AfterViewChecked, Component, ElementRef, Renderer2} from '@angular/core';
import {firstValueFrom} from "rxjs";
import {Module} from "../../../../../interfaces/module.interface";
import {Page} from "../../../../../interfaces/page.interface";
import {PaginatorData} from "../../../../../interfaces/paginator-data.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {EventService} from "../../../services/event.service";
import {ModuleService} from "../../../../modules/services/module.service";
import {findModuleOrPageBySlug} from "../../../../../helpers/module.helper";
import {
  getDateDDMMYYYY,
  getHourInFormat12HRS,
  getLinkImage,
  getThumbnailVideo
} from "../../../../../helpers/converters.helper";
import Event from "../../../interfaces/event.interface";
import {
  EventByGroupYear, listMonthEventContentData,parseDatagroupEventDetail,
  permissionsFormRequired
} from "../../../../events-activities/events.helper";
import {DeleteModalComponent} from "../../../../../components/delete-modal/delete-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MultimediaEvento} from "../../../../../interfaces/link-evento.interface";
import {MultimediaModalComponent} from "../../../../../components/video-modal/multimedia-modal.component";

@Component({
  selector: 'app-news-list-page',
  templateUrl: './event-monthly-list-page.component.html',
  styleUrls: ['./event-monthly-list-page.component.scss']
})
export class EventMonthlyListPageComponent implements AfterViewChecked {
  notifications: Map<string, string> = new Map<string, string>();
  showNotifications: boolean = false;
  pageInfo!: Module | Page
  events!: Event[]
  eventType!: string;
  paginator !: PaginatorData;
  eventByGroupYear: EventByGroupYear[] = [];
  monthsObjetList:{value: string, text: string}[] = [];

  filterType: string = 'event';
  slugFilter: string = '';
  dateFilter: any;
  filter: any;

  constructor(
    private route: ActivatedRoute,
    private readonly eventsService: EventService,
    private readonly moduleService: ModuleService,
    private router: Router,
    private dialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewChecked() {
    const contentEventElements = this.el.nativeElement.querySelectorAll('.content-event');
    contentEventElements.forEach((contentEventElement: any) => {
      const images = contentEventElement.querySelectorAll('img');
      images.forEach((img: any) => {
        this.renderer.setStyle(img, 'width', '100%');
      });
    });
  }

  async ngOnInit() {
    this.route.url.subscribe(segments => {
      const slug = segments.join('/');
      this.slugFilter = slug;
      this.eventType = segments.join('.')
      this.getPageInfo(slug)
      this.loadFilters();
    });
  }

  getPageInfo(slug: string) {
    this.moduleService.getAll().subscribe({
      next:response => {
        const modules: Module[] = response.body.data
        const pageInfo: Module | Page | null = findModuleOrPageBySlug(modules, slug)
        if (pageInfo) {
          this.pageInfo = pageInfo
        }
      },
      error: () => {
        this.notifications.set('not-save', 'Se han actualizado los permisos, refresca la página con F5!!');
        this.showNotifications = true;
      }
    });
  }

  edit(id: number) {
    const url = `${this.pageInfo.slug}/${id}/editar`;
    this.router.navigateByUrl(url);
  }

  openDialogDelete(enterAnimationDuration: string, exitAnimationDuration: string, id: number): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });

    dialogRef.componentInstance.deleteConfirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.onRemoveEvent(id);
      }
    });
  }

  onRemoveEvent(id: number) {
    this.eventsService.delete(id)
      .subscribe((res) => {
        if (res.ok) {
          this.getEvents();
        }
      });
  }

  handleImageError(event: any): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.onerror = null;
    imgElement.src = '';
    imgElement.alt = 'Multimedia dañada o eliminada, revise el link de la imagen o video';
    const parentElement = imgElement.parentElement;
    if (parentElement) {
      parentElement.classList.add('disabled');
      parentElement.innerHTML = 'Multimedia dañada o eliminada, revise el link de la imagen o video';
    }
  }

  openMultimediaModal(multimedia: MultimediaEvento) {
    this.dialog.open(MultimediaModalComponent, {
      data: {
        multimedia: multimedia
      }
    });
  }

  truncateText(text: string) {
    if (text.length < 70) return text
    return `${text?.slice(0, 70)}...`
  }

  getEvents(
    dateStart: string = '',
    dateEnd: string = '',
    search: string = ''
  ) {
    const eventType = this.eventType
    this.eventsService.getAll(eventType, dateStart, dateEnd, search, '', 6,'detail').subscribe({
      next: response => {
        this.events = response.body.data;
        this.monthsObjetList = listMonthEventContentData(response.body.data);
        this.paginator = {links: response.body.links, meta: response.body.meta};
        this.eventByGroupYear = parseDatagroupEventDetail(response.body.data);
      },
      error: () => {
        this.notifications.set('not-save', 'Se han actualizado los permisos, refresca la página con F5!!');
        this.showNotifications = true;
      }
    })
  }

  async onPageSelected(url: string) {
    this.eventsService.getByUrlPagination(url).subscribe({
      next: response => {
        this.events = response.body.data;
        this.paginator = {links: response.body.links, meta: response.body.meta};
      }
    })
  }

  async onRemovePost(id: number) {
    try {
      const response = await firstValueFrom(
        this.eventsService.delete(id)
      );
      if (response.ok) {
        await this.ngOnInit();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  private loadFilters() {
    const storedFilters = localStorage.getItem('filters');
    if (storedFilters) {
      this.filter = JSON.parse(storedFilters);

      if (this.shouldClearFilters()) {
        this.clearFilters();
      } else {
        this.applyStoredFilters();
      }
    } else {
      this.getEvents();
    }
  }

  private shouldClearFilters(): boolean {
    return this.filterType !== this.filter.type || this.slugFilter !== this.filter.slug;
  }

  private clearFilters() {
    localStorage.removeItem('filters');
    this.dateFilter = '';
    this.getEvents();
  }

  private applyStoredFilters() {
    this.dateFilter = this.filter.date;
    this.getEvents();
    this.onFilter(this.filter.date);
  }

  onFilter(value: string) {
    setTimeout(() => {
      this.scrollToSection(value);
      this.saveFilters(value);
    }, 0);
  }

  private scrollToSection(value: string) {
    const containerSection = document.getElementById(`section_${value}`);
    const offset = window.innerWidth < 1080 ? 180 : 200;

    if (containerSection) {
      containerSection.scrollIntoView();
      window.scrollTo({
        top: window.scrollY - offset,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  private saveFilters(date: string) {
    const filters = {
      type: 'event',
      slug: this.slugFilter,
      date: date,
    };
    localStorage.setItem('filters', JSON.stringify(filters));
  }

  protected readonly getDateDDMMYYYY = getDateDDMMYYYY;
  protected readonly getLinkImage = getLinkImage;
  protected readonly hasPermissionCreated = permissionsFormRequired;
  protected readonly getThumbnailVideo = getThumbnailVideo;
  protected readonly getHourInFormat12HRS = getHourInFormat12HRS;
}
