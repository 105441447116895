import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { Location } from '@angular/common';
import UserDataLogin from "../../interfaces/user-data-login.interface";
import { PermissionsService } from 'src/app/services/permissions.service';
import { TYPE_PERMISSIONS } from 'src/app/types/constants/permission.enum';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filter, Subscription } from "rxjs";
import { UserService } from "../../services/user.service";
import { Module } from "../../interfaces/module.interface";
import { ModuleService } from "../../pages/modules/services/module.service";
import { RouteService } from "../../services/route.service";
import { getLinkImage } from 'src/app/helpers/converters.helper';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements AfterViewInit {
  @ViewChild(MatAutocomplete) auto!: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;
  @ViewChild('initEvent') linkInitEvent!: ElementRef;
  @ViewChild('initReports') linkInitReports!: ElementRef;
  @ViewChild('initFairs') linkInitFairs!: ElementRef;
  @Output() linkClicked = new EventEmitter<boolean>();
  fullName: string = '';
  firstLetterName: string = '';
  avatarUrl: string = '';
  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;

  private nombreUsuarioSubscription: Subscription;
  private moduleSuscription: Subscription;
  modules!: Module[]

  constructor(
    private router: Router,
    private permissionsService: PermissionsService,
    private moduleService: ModuleService,
    private routeService: RouteService,
    private location: Location,
    private userService: UserService,
  ) {
    // Inicializar el Subject del nombre de usuario
    this.nombreUsuarioSubscription = this.userService.getNameUserSubject().subscribe(
      (nuevoNombreUsuario) => {
        const name = nuevoNombreUsuario || '';
        this.fullName = name.split(' ')[0];
      }
    )
    this.moduleSuscription = this.moduleService.getModulesSubject().subscribe(
      (modules) => {
        this.modules = modules
        this.routeService.setInitialRoutes(this.modules)
      }
    )
  }

  ngAfterViewInit() {
    this.auto = this.auto;
  }

  ngOnInit() {
    const data = this.getUserData();
    if (data) {
      const name = data.name;
      this.fullName = name.split(' ')[0];
      this.avatarUrl = getLinkImage(data?.image);
      this.firstLetterName = name.charAt(0).toUpperCase();
    }
    this.getCompleteModules()
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.updateDropdown()
      });
  }
  updateDropdown() {
    const rutaActual = this.router.url;
    if (this.modules && Array.isArray(this.modules)) {
      this.modules.forEach(module => {
        module.dropdown = rutaActual.includes(module.slug);
      });
    }
  }


  getCompleteModules() {
    this.moduleService.getAll().subscribe({
      next: response => {
        this.modules = response.body.data
        this.updateDropdown()
        // this.routeService.setInitialRoutes(this.modules)
      },
      error: err => {
        this.goToPreviousRoute();
      }
    })
  }

  goToPreviousRoute() {
    const currentUrl = this.router.url; // Obtiene la URL actual
    const urlSegments = currentUrl.split('/'); // Divide la URL en segmentos
    urlSegments.pop(); // Elimina el último segmento de la URL

    const previousUrl = urlSegments.join('/'); // Recompone la URL anterior
    this.router.navigateByUrl(previousUrl); // Redirige a la URL anterior
    this.getCompleteModules();
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  async logout() {
    localStorage.clear();
    await this.router.navigate(['/login']);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  onArrowDown() {
    if (this.trigger.activeOption) {
      this.trigger.activeOption.focus();
    } else {
      this.trigger.openPanel();
      const firstOption = document.querySelector('mat-option') as HTMLElement;
      if (firstOption) {
        firstOption.focus();
      }
    }
  }

  onArrowUp() {
    if (this.trigger.activeOption) {
      const options = document.querySelectorAll('mat-option');
      const index = Array.from(options).indexOf(this.trigger.activeOption._getHostElement());
      const previousOption = options[index - 1] as HTMLElement;
      if (previousOption) {
        previousOption.focus();
      }
    }
  }

  ngOnDestroy() {
    // Cancelar la suscripción cuando el componente se destruye
    this.nombreUsuarioSubscription.unsubscribe();
    this.moduleSuscription.unsubscribe();
  }

  emitLinkClicked() {
    this.linkClicked.emit(true);
  }
}
