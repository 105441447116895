import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {catchError, Observable, tap, throwError} from "rxjs";

@Injectable()
export class HttpCustomInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.getToken()
    const clonedRequest = request.clone(
      {headers: request.headers.append('Authorization', `Bearer ${token}`)}
    );

    return next.handle(clonedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status === 401) {
            this.handleUnauthorized();
          }
        }
      ),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.handleUnauthorized();
        }
        return throwError(error);
      })
    );
  }

  private getToken() {
    let token = '';
    const userData = localStorage.getItem('UserData');
    if (userData) {
      const data = JSON.parse(userData);
      token = data.token
    }
    return token
  }

  private handleUnauthorized(): void {
    localStorage.removeItem('UserData');
    this.router.navigate(['/login']);
  }
}
