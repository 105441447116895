import {Component} from '@angular/core';
import {firstValueFrom} from "rxjs";
import {Router} from "@angular/router";
import {CompanyService} from "../../services/company.service";
import {UserService} from "../../services/user.service";
import {environment} from "../../../environments/environment";

interface Image {
  ID_MULTI: string;
  ID_US: string;
  TIPO: string;
  FILE_NAME: string;
  FECHA_CREATED: string;
  MODIFICADO: string;
  RUTA: string;
}

interface User {
  ID_US: string;
  NOMBRES: string;
  APELLIDO_PATERNO: string;
  APELLIDO_MATERNO: string;
  EMAIL: string;
  TELEFONO: string;
  CARGO: string;
  DIRECCION: string;
  FECHA_INGRESO: string;
  admin_asociado: string;
  estado: string;
  TIPO: string;
  ID_INMOB: string;
  NOMBRE_INMOB: string;
  URL_INMOB: string | null;
  imagenes: Image[];
}

@Component({
  selector: 'app-directorio-afiliados-inicio',
  templateUrl: './directorio-afiliados-inicio.component.html',
  styleUrls: ['./directorio-afiliados-inicio.component.scss']
})
export class DirectorioAfiliadosInicioComponent {
  users:User[] = []

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    await this.getUsers();
  }

  async getUsers() {
    const users = await firstValueFrom(this.userService.getAll('','7'));
    if (users) {
      this.users = users;
    }
  }
  getImage(path: string) {
    const URL = environment.url;
    return URL + '/' + path;
  }
}
