import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import Event from '../../pages/events/interfaces/event.interface';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-section-filter',
  templateUrl: './section-filter.component.html',
  styleUrls: ['./section-filter.component.scss']
})
export class SectionFilterComponent {
  @Input() searchAll!: string;
  @Input() dateStartFilter: any;
  @Input() dateEndFilter: any;
  @Input() typeFilter = 'standard';
  @Input() titleFilter = '';
  @Input() List: {value: string, text: string}[] = [];
  @Input() version: string = "one";
  @Input() showTypeFilter: boolean = true;
  @Input() isPopupFilter: boolean = false;

  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterList: EventEmitter<string> = new EventEmitter();
  @Output() submitFilterEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  isScrolled = false;
  isShowButtonOpen = true;
  @Input() listValue: any;

  async onFilter() {
    this.submitFilterEvent.emit(true);

    this.filterEvent.emit({
      searchAll: this.searchAll,
      dateStartFilter: this.dateStartFilter,
      dateEndFilter: this.dateEndFilter
    });
  }

  onFilterList(year: any){
    this.listValue = year;
    this.filterList.emit(year);
  }

  clearFilterYear(year: any){
    this.listValue = null;
    this.filterList.emit('');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollY = window.scrollY || window.pageYOffset;
    this.isScrolled = scrollY > 0;
  }

  async clearSearchInput() {
    this.searchAll = '';
  }

  onClickButtonShow(value: boolean){
    this.isShowButtonOpen = value;
  }

  onClear(){
    this.dateEndFilter = '';
    this.dateStartFilter = '';
    this.searchAll = '';
    this.onFilter();
  }
}
