import {AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, forwardRef} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidatorFn, Validators} from "@angular/forms";
import { regex, validatorDni, validatorPhone, validatorRuc, validatorUrl } from '../../helpers/input-group.helper';
import MESSAGE_ERROR from 'src/app/helpers/message-error.helper';
import { getDateDDMMYYYY } from 'src/app/helpers/converters.helper';
import { DateTime } from 'luxon';
import { PrimeNGConfig } from 'primeng/api';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputGroupComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => InputGroupComponent),
  multi: true,
};
@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html',
  styleUrls: ['./input-group.component.scss'],
  providers: [
    VALUE_ACCESSOR,
    VALIDATOR,
  ]
})
export class InputGroupComponent implements OnInit, ControlValueAccessor, DoCheck {
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef<HTMLInputElement>;

  @Input() title: string = '';
  @Input() value: string | null = null;
  @Input() type: string = 'text';
  @Input() maxlength: number = 250;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() colorLabelInverse: boolean = false;
  @Input() errorMessage: string | null = null;
  @Input() required: boolean = false;
  @Input() name = '';
  @Input() mode: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() counter: boolean = false;
  currentLength: number = 0;
  es: any;
  urlPattern: RegExp = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
  videoPattern: RegExp = /\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv)$/i;
  youtubePattern: RegExp = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  vimeoPattern: RegExp = /^(https?:\/\/)?(www\.)?vimeo\.com\/.+$/;
  googleDrivePattern: RegExp = /^(https?:\/\/)?(drive\.google\.com\/file\/d\/.+)$/;


  formControl!: NgControl;

  private onChange: any = () => {};
  private onTouch: any = () => {};

  constructor(
    private readonly primengConfig: PrimeNGConfig,
  ) {}

  ngOnInit(): void {
    this.primengConfig.setTranslation({
      firstDayOfWeek: 1,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Sm'
    });
    this.currentLength = this.value?.length ?? 0;
  }

  ngAfterViewInit() {
    this.currentLength = 0;
  }

  ngOnChanges(changes: any) {
    this.currentLength = this.value?.length ?? 0;
  }

  getInputType(){
    switch(this.type){
      case "text":
      case "ruc":
      case "dni":
      case "email":
      case "phone":
        return "text";
      case "password":
        return "password";
      case "currentPassword":
        return "password";
      case"url":
        return "url";
      case "number":
        return "number";
      case "dateEvent":
      case "date":
        return "date";
      case "time":
        return "time";
      case "month":
        return "month";

      default:
        return "text";
    }
  }

  ngDoCheck() {
    this.invalid = this.validateInput(this.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.currentLength = value?.length ?? 0;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInputChange(value: any) {
    this.currentLength = value.length;
    if(this.type == 'date') {
      value = getDateDDMMYYYY(value);
    }
    this.validateInput(value);
    this.onChange(value);
    this.onTouch();
    this.valueChange.emit(value);
  }

  validateInput(value: any) {
    this.errorMessage = '';
    if (this.required && (!value || value === '')) {
      this.errorMessage = MESSAGE_ERROR.OBLIGATORY_FIELD;
      return true;
    } else if ((this.required || value) && this.type === 'letter' && !regex.letter.test(value)) {
      this.errorMessage = 'El formato es inválido. Solo debe tener letras.';
      return true;
    } else if ((this.required || value) && this.type === 'text' ) {
      if( this.maxlength != 0 && this.maxlength < value.length ) {
        this.errorMessage = MESSAGE_ERROR.MAX_TEXT_CHARACTER(value.length, this.maxlength);
        return true;
      }
      return false;
    } else if ((this.required || value) && this.type === 'url' && !regex.url.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID_URL;
      return true;
    }else if ((this.required || value) && this.type === 'email' && !regex.email.test(value)) {
      this.errorMessage = MESSAGE_ERROR.EMAIL_IS_FIELD;
      return true;
    }else if ((this.required || value) && this.type === 'password' && !regex.password.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_PASSWORD;
      return true;
    }else if ((this.required || value) && this.type === 'ruc' && !regex.ruc.test(value)) {
      this.errorMessage = MESSAGE_ERROR.RUC_FIELD_HAS_11_CHARACTER;
      return true;
    }else if ((this.required || value) && this.type === 'dni' && !regex.dni.test(value)) {
      this.errorMessage = MESSAGE_ERROR.DNI_FIELD_HAS_8_CHARACTER;
      return true;
    }else if ((this.required || value) && this.type === 'phone' && !regex.phone.test(value)) {
      this.errorMessage = MESSAGE_ERROR.FORMAT_INVALID_PHONE;
      return true;
    }else {
      return false;
    }
  }

  validate: ValidatorFn = (control: AbstractControl) => {
    const validators: ValidatorFn[] = [];
    if (validators.length) {
      const validator = Validators.compose(validators);
      const result = validator ? validator(control) : null;
      return result;
    }
    return null;
  };
  onInputClick(event: any) {
    let i = event.currentTarget.querySelector( 'i' )
    i.classList.toggle( 'pi-eye' )
    i.classList.toggle('pi-eye-slash')
    if( i.classList.contains('pi-eye') ) {
      this.inputElement.nativeElement.type = 'password'
    } else if( i.classList.contains('pi-eye-slash') ) {
      this.inputElement.nativeElement.type = 'text'
    }
  }
}
