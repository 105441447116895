<div class="section-filter {{ isScrolled  ? 'scroll-fixed-filter': '' }}">
  @if (isScrolled) {
    <div class="container_button">
      @if (isShowButtonOpen) {
        <button class="btn open_button_filter primary" (click)="onClickButtonShow(false)">Ver filtros</button>
      }
    </div>
  }
  <div class="filters {{ !isShowButtonOpen || !isScrolled ? 'active': '' }}">
    @if (typeFilter == 'standard') {
      <div class="container_filters">
        @if (!isPopupFilter) {
          <div class="search">
            @if(showTypeFilter == true){
              <input type="text" placeholder="Buscar"
                [(ngModel)]="searchAll" (keyup.enter)="onFilter()">
              <span class="icon">
                <mat-icon (click)="!searchAll ? null : clearSearchInput()">
                  {{ !searchAll ? 'search' : 'close' }}
                </mat-icon>
              </span>
            }
          </div>
        }
      </div>

      <div class="actions">
        <button class="btn primary" (click)="onFilter()">Buscar</button>
        <button class="btn primary" (click)="onClear()">Limpiar</button>
        @if (!isShowButtonOpen && isScrolled) {
          <button class="btn close_button_filter" (click)="onClickButtonShow(true)">Cerrar</button>
        }
      </div>
      @if (version=='one') {
        <div class="dates">
          <app-input-group
            title="Fecha de inicio"
            type="date"
            [colorLabelInverse]="isScrolled"
            [value]="dateStartFilter"
            (valueChange)="dateStartFilter = $event"
          ></app-input-group>
          <app-input-group
            title="Fecha de fin"
            type="date"
            [colorLabelInverse]="isScrolled"
            [value]="dateEndFilter"
            (valueChange)="dateEndFilter = $event"
          ></app-input-group>
        </div>
      }
    }
  @if (typeFilter === 'list') {
    <div class="filter_year">
      <app-select-group
        class="filter-year"
        [value]="listValue"
        [colorLabelInverse]="isScrolled"
        [title]="'Filtra por ' + titleFilter"
        (valueChange)="onFilterList($event)"
        [options]="List"
      ></app-select-group>
      @if (listValue) {
        <a
          class="button_filter_year_clear {{ isScrolled ? 'link_inverse_clear': '' }}"
          (click)="clearFilterYear($event)"
          >
          Limpiar Selección
        </a>
      }
    </div>
    @if (!isShowButtonOpen && isScrolled) {
      <button class="btn primary close_button_filter" (click)="onClickButtonShow(true)">Cerrar</button>
    }
  }
</div>
</div>
<!-- <div *ngIf="version === 'year'" class="section-filter {{ isScrolled  ? 'scroll-fixed-filter': '' }}">

</div> -->
