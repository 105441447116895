import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable, Subject} from "rxjs";
import {map} from 'rxjs/operators';
import UserData from "../interfaces/user-data.interface";

const URL = environment.url + '/api/v1';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private nameUserSubject = new Subject<string>();

  private httpOptions = {};

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Content-Type": "application/json"
      }),
      observe: 'response' as const // Asegura que el tipo de 'observe' sea correcto
    }
  }

  getAll(inmobiliariaId: string = '', limit: string = '500'): Observable<any[]> {
    const url = `${URL}/users?limit=${limit}&inmobiliariaId=${inmobiliariaId}`;
    return this.http.get<any>(url).pipe(
      map(response => response?.data?.users)
    );
  }

  getOne(userId: string): Observable<UserData> {
    const url = `${URL}/user/${userId}`;
    return this.http.get<any>(url).pipe(
      map(response => response?.data)
    );
  }

  async getPermissions() {
    const jsonData = await localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data.permisos;
  }

  setNameUser(nombre: string, image: string) {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    userData.data.name = nombre;
    userData.data.image = image;
    localStorage.setItem('UserData', JSON.stringify(userData));
    this.nameUserSubject.next(nombre);
  }

  getNameUser(): string {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data.name;
  }

  getNameUserSubject(): Subject<string> {
    return this.nameUserSubject;
  }
  getUserStatus() {
    const url = `${URL}/users/status`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  getEmailsUserStatus(type:string) {
    const url = `${URL}/users/status/${type}`;
    return this.http.get<any>(url, this.httpOptions).pipe(
      map(response => response)
    );
  }
  getEmailsUserStatusExcel(type:string) {
    const url = `${URL}/users/status/${type}/excel`;
    return this.http.get(url, { responseType: 'blob' });
  }
  getUserLoginExcel() {
    const url = `${URL}/users-login/excel`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
