import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, map, of, switchMap, throwError} from 'rxjs';
import {defaultCompany, defaultEmployees, defaultContacts, defaultOthers} from 'src/app/helpers/affiliate-directory.helper';
import {getLinkImage} from 'src/app/helpers/converters.helper';
import {CompanyEmployee} from 'src/app/interfaces/company-employee.interface';
import {CompanyService} from 'src/app/services/company.service';
import {FilesService} from 'src/app/services/files.service';
import {PermissionsService} from 'src/app/services/permissions.service';
import {TYPE_PERMISSIONS} from 'src/app/types/constants/permission.enum';
import UserDataLogin from "../../../../interfaces/user-data-login.interface";
import {LoginComponent} from "../../../../auth/login/login.component";
import { ConfirmationService, MessageService } from 'primeng/api';
import VALIDATORS_INPUT from 'src/app/helpers/validators-input.helper';
import { getErrorMessage } from 'src/app/helpers/validators.helper';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-affiliate-directory-form-page',
  templateUrl: './affiliate-directory-form-page.component.html',
  styleUrls: ['./affiliate-directory-form-page.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AffiliateDirectoryFormPageComponent implements OnInit {
  @ViewChild('form', {static: false}) public form!: NgForm;
  @ViewChild('top') topElement!: ElementRef;
  @ViewChild('additionalUser') addUserElement!: ElementRef;
  titlePage: string = "";
  company!: CompanyEmployee;
  firstLetter: string = "";
  isEditMode: boolean = false;
  notifications: Map<string, string> = new Map<string, string>();
  selectOptionsType = [
    {value: 1, label: 'Asociados'},
    {value: 2, label: 'Aliados estratégicos'},
  ]

  typePermission: typeof TYPE_PERMISSIONS = TYPE_PERMISSIONS;
  disabledField: boolean = false;
  sizeAvatar = 'md'
  protected readonly getLinkImage = getLinkImage;

  groupedEmployees!: any[];
  selectedEmployees!: any[];

  nameValidate: string | null = null;
  businessNameValidate: string | null = null;
  documentNumberValidate: string | null = null;
  errorsValidate: any = {};

  isRepresentative: any;

  constructor(
    private route: ActivatedRoute,
    private inmobiliariaService: CompanyService,
    private permissionsService: PermissionsService,
    private router: Router,
    private filesService: FilesService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {
    // if(this.can(this.typePermission.CREATE_EMPLOYESS_DIRECTORY_AFFILIATES)){
    //   this.router.navigate([`/`]);
    // }
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const paramId = params.get('id');
          if (this.router.url.includes('nuevo')) {
            this.titlePage = 'Nuevo Afiliado';
            const defaultAffiliateDirectory: CompanyEmployee = defaultCompany();
            return of(defaultAffiliateDirectory);
          } else if (parseInt(paramId || '') && paramId) {
            this.titlePage = 'Editar Afiliado';
            return this.inmobiliariaService.getById(paramId).pipe(
              catchError((err) => {
                this.notifications.set(
                  'getAffiliate',
                  err.error.message
                );
                return throwError(() => err);
              }),
              map((res) => {
                if (res && res.body && res.body.data) {
                  return res.body.data;
                }
              })
            );
          }
          return throwError(() => new Error('Invalid ID'));
        })
      )
      .subscribe((res) => {
        const parseNews = res;
        this.company = parseNews;
        const data = this.getUserData();
        const isAdminOrWriter = data?.roles.some((rol) => rol.name === 'admin' || rol.name === 'writer');
        const email = data?.email;
        const representatives = this.company.employees.legalRepresentatives;
        this.isRepresentative = representatives.some((r) => r.email === email);

        const canUpdateData = isAdminOrWriter || this.isRepresentative;

        if (!canUpdateData) {
          this.disabledField = true;
          this.titlePage = '';
          this.sizeAvatar = 'lg'
        }
      });

      this.route.paramMap.subscribe(params => {
        this.isEditMode = !params.has('id');
      });
  }

  getUserData(): UserDataLogin | undefined {
    const jsonData = localStorage.getItem('UserData');
    const userData = jsonData ? JSON.parse(jsonData) : undefined;
    return userData.data;
  }

  getErrorMessages(field: string, index: number): string | null {
    const key = `${field}.${index}.email`;
    return this.errorsValidate[key]?.[0] || null;
  }

  canSave(): boolean {
    return Boolean(this.form && this.form.valid);
  }

  validateForm(company: any): boolean {
    if (!company.employees?.others?.length) {
      return true;
    }

    if (!company.employees?.legalRepresentatives?.length) {
      this.notifications.set(
        'createAffiliate',
        'Datos incompletos, revise los campos de los usuarios!'
      );
      return false;
    }

    return !company.employees.others.some((additionalUser: any) => {
      if (
        company.employees.legalRepresentatives[0]?.email &&
        additionalUser?.email &&
        company.employees.legalRepresentatives[0].email === additionalUser.email
      ) {
        this.notifications.set(
          'createAffiliate',
          'Hay datos duplicados, revise los campos de los usuarios!'
        );
        return true;
      }
      return false;
    });
  }

  save(event: Event) {
    if (this.canSave()) {
      const isValidate = this.validateForm(this.company);
      if (isValidate) {
        if (!this.company.id) {
          this.inmobiliariaService.create(this.company)
          .subscribe({
            next: () => {
                localStorage.removeItem('avatarUrl');
                this.showSuccess('Afiliado Creado.')
                this.notifications.clear();
                this.form.reset();
                this.company.image = '';

            },
            error: (error) => {
              this.notifications.set(
                'createAffiliate',
                'Hay datos duplicados, revise por favor!'
              );

              const { name, businessName, documentNumber } = error.error.errors
              this.nameValidate = name;
              this.businessNameValidate = businessName;
              this.documentNumberValidate = documentNumber;
              this.errorsValidate = error.error.errors;
            }
          });
        } else {
          this.inmobiliariaService.update(this.company)
            .subscribe({
              next: () => {
                localStorage.removeItem('avatarUrl');
                this.notifications.clear();
                this.showSuccess('Afiliado Actualizado.')
                if (this.isRepresentative) {
                  const currentUserEmail = this.getUserData()?.email;
                  const newUserEmail = this.company.employees.legalRepresentatives[0].email;
                  if (currentUserEmail != newUserEmail) {
                    localStorage.clear();
                    localStorage.setItem('updateAssociate', 'true');
                    this.router.navigate(['/login']);

                  }
                }
              },
              error: (error) => {
                this.notifications.set(
                  'createAffiliate',
                  error.error.message,
                );

                const { name, businessName, documentNumber } = error.error.errors
                this.nameValidate = name;
                this.businessNameValidate = businessName;
                this.documentNumberValidate = documentNumber;
                this.errorsValidate = error.error.errors;
              }
            })
        }
      }
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  saveOthers() {
    if (this.canSave()) {
      if (!this.company.id) {
        // this.inmobiliariaService.create(this.company)
        //   .pipe(
        //     catchError((error) => {
        //       this.notifications.set(
        //         'createAffiliate',
        //         'no se pudo guardar intentelo mas tarde'
        //       );
        //       return throwError(() => error);
        //     })
        //   )
        //   .subscribe(data => {
        //     if (data.ok) {
        //       localStorage.removeItem('avatarUrl');
        //       this.router.navigate([`/directorio-afiliados`]);
        //     }
        //   })
      } else {
        this.inmobiliariaService.updateOthers(this.company)
          .pipe(
            catchError((error) => {
              this.notifications.set(
                'UpdateAffiliate',
                'no se pudo guardar intentelo mas tarde'
              );
              return throwError(() => error);
            })
          )
          .subscribe(data => {
            if (data.ok) {
              localStorage.removeItem('avatarUrl');
              this.router.navigate([`/directorio-afiliados`]);
              this.notifications.clear();
            }
          })
      }
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  saveUserAdditional() {
    if (this.canSave()) {
      if (!this.company.id) {
        // this.inmobiliariaService.create(this.company)
        //   .pipe(
        //     catchError((error) => {
        //       this.notifications.set(
        //         'createAffiliate',
        //         'no se pudo guardar intentelo mas tarde'
        //       );
        //       return throwError(() => error);
        //     })
        //   )
        //   .subscribe(data => {
        //     if (data.ok) {
        //       localStorage.removeItem('avatarUrl');
        //       this.router.navigate([`/directorio-afiliados`]);
        //     }
        //   })
      } else {

        if( this.company.users_additional ) {
          this.inmobiliariaService.createUserAdditional(this.company.id, this.company.users_additional)
          .pipe(
            catchError((error) => {
              this.notifications.set(
                'UpdateAffiliate',
                'no se pudo agregar usuario adicional intentelo mas tarde'
              );
              return throwError(() => error);
            })
          )
          .subscribe(data => {
            if (data.ok) {
              this.notifications.clear();
              this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: 'Usuarios agregados!' });
              this.scrollToTop();
            }
          })
        }
      }
    } else {
      // this.scrollToTop();
      // this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  getLetter() {
    return this.company.name.charAt(0).toUpperCase()
  }

  scrollToTop() {
    this.topElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  scrollToAddUser() {
    this.addUserElement.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  addRepresentative() {
    if (this.canSave()) {
      this.company.employees.legalRepresentatives.push(defaultEmployees());
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  //contact
  addAssistant() {
    if (this.canSave()) {
      this.company.employees.assistants.push(defaultEmployees());
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  addContact() {
    if (this.canSave()) {
      this.company.employees.contacts.push(defaultContacts());
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  addOther() {
    if (this.canSave()) {
      this.company.employees.others.push(defaultOthers());
    } else {
      this.scrollToTop();
      this.notifications.set('not-save', 'Hay campos requeridos por llenar verificar!!');
    }
  }

  removeRepresentative(index: number) {
    this.company.employees.legalRepresentatives.splice(index, 1);
  }

  removeAssistant(index: number) {
    this.company.employees.assistants.splice(index, 1);
  }

  removeContact(index: number) {
    this.company.employees.contacts.splice(index, 1);
  }

  removeOther(index: number) {
    this.company.employees.others.splice(index, 1);
  }

  onCancel() {
    this.router.navigate([`/directorio-afiliados`]);
  }

  can(action: TYPE_PERMISSIONS) {
    return this.permissionsService.can(action);
  };

  errorLoadImage(event: string) {
    this.notifications.set('errorLoadImage', event);
  }

  updateImage(event: string) {
    this.company.image = event;
  }

  onChangeSliderState(event: boolean) {
    this.company.isActive = event;
  }

  onSelectedValueChange(newValue: string | number): void {
    this.company.companyType.id = Number(newValue);
  }

  renderDataSelectedItems( employees:any, { label, value } :any ) {
    this.groupedEmployees.push(
      {
        label,
        value,
        items: employees.map( (employee:any) => {
            if( employee.hasUser ) {
              this.selectedEmployees.push( employee.id )
            }
            return ({ label: `${employee.name} ${employee.fatherName } ${employee.motherName} `, value: employee.id })
          })
      }
    )
  }

  renderDataSelect( employees:any ){
    this.groupedEmployees = []
    this.selectedEmployees = []
    if( employees.assistants.length != 0 ) {
      this.renderDataSelectedItems( employees.assistants, { value: 'ast', label: 'ASISTENTE' } )
    }
    if( employees.contacts.length != 0 ) {
      this.renderDataSelectedItems( employees.contacts, { value: 'cont', label: 'CONTACTO' } )
    }
    if( employees.others.length != 0 ) {
      this.renderDataSelectedItems( employees.others, { value: 'otr', label: 'OTROS' } )
    }
    this.company.users_additional = [...this.selectedEmployees]
  }

  onChangeSelect(event:any){
    this.company.users_additional = [ ...event.value ]
  }

  showSuccess(message: string) {
    this.messageService.add({ severity: 'success', summary: 'Operación exitosa', detail: message, life: 3000 });
  }

  showError(message: string) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  protected readonly getErrorMessage = getErrorMessage;
  protected readonly validatorsInput = VALIDATORS_INPUT;
}
